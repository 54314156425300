import React from 'react'
import { css } from '@emotion/core'
import { Nav } from '../components/nav'

const centered = css`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`

const NotFoundPage = () => {
  return (
    <>
      <Nav />
      <h1 css={centered}>Not Found</h1>
    </>
  )
}

export default NotFoundPage
